export const externalUrls = {
  appDownload: "https://nobodysurf.onelink.me/vriP/c75f01bb",
  shop: "https://shop.nobodysurf.com",
  youtube: "https://www.youtube.com/NobodySurf",
  instagram: "https://www.instagram.com/nobody_surf/",
  facebook: "https://www.facebook.com/NobodySurf/",
  pinterest: "https://www.pinterest.com/nobodysurf/",
  spotify: "https://open.spotify.com/user/45dw74udm4o3ys8bj3hqlnx6a",
  tiktok: "https://www.tiktok.com/@nobodysurf",
}

const config = {
  navigations: {
    subNavigation: [
      {
        id: "286",
        title: "Shortboard",
      },
      {
        id: "285",
        title: "Longboard",
      },
      {
        id: "280",
        title: "Alternative",
      },
      {
        id: "394",
        title: "Womens",
      },
      {
        id: "415",
        title: "Originals",
      },
    ],
    menuNavigation: [
      {
        id: "1",
        title: "Download NobodySurf",
        to: externalUrls.appDownload,
        icon: "MoviePlayIcon",
        isInternal: false,
      },
      {
        id: "2",
        title: "What's Up",
        to: "/whats-up",
        icon: "ArticleIcon",
        isInternal: true,
      },
      {
        id: "3",
        title: "About Us & Supporters Club",
        to: "/about",
        icon: "NobodySurfIcon",
        isInternal: true,
      },
      {
        id: "4",
        title: "Online Shop",
        to: externalUrls.shop,
        icon: "ShopIcon",
        isInternal: false,
      },
      {
        id: "4",
        title: "Social Media",
        links: [
          {
            id: "1",
            title: "Instagram",
            to: externalUrls.instagram,
            icon: "InstagramIcon",
            isInternal: false,
          },
          {
            id: "2",
            title: "Facebook",
            to: externalUrls.facebook,
            icon: "FacebookIcon",
            isInternal: false,
          },
          {
            id: "3",
            title: "YouTube",
            to: externalUrls.youtube,
            icon: "YouTubeIcon",
            isInternal: false,
          },
          {
            id: "4",
            title: "TikTok",
            to: externalUrls.tiktok,
            icon: "TikTokIcon",
            isInternal: false,
          },
          {
            id: "5",
            title: "Pinterest",
            to: externalUrls.pinterest,
            icon: "PinterestIcon",
            isInternal: false,
          },
          {
            id: "6",
            title: "Spotify",
            to: externalUrls.spotify,
            icon: "SpotifyIcon",
            isInternal: false,
          },
        ],
      },
    ],
    footer: [
      {
        id: "1",
        title: "Social Media",
        links: [
          {
            id: "1",
            title: "Instagram",
            to: externalUrls.instagram,
            isInternal: false,
          },
          {
            id: "2",
            title: "Facebook",
            to: externalUrls.facebook,
            isInternal: false,
          },
          {
            id: "3",
            title: "YouTube",
            to: externalUrls.youtube,
            isInternal: false,
          },
          {
            id: "4",
            title: "TikTok",
            to: externalUrls.tiktok,
            isInternal: false,
          },
          {
            id: "5",
            title: "Pinterest",
            to: externalUrls.pinterest,
            isInternal: false,
          },
          {
            id: "6",
            title: "Spotify",
            to: externalUrls.spotify,
            isInternal: false,
          },
        ],
      },
      {
        id: "2",
        title: "Legal",
        links: [
          {
            id: "1",
            title: "Privacy Policy",
            to: "/privacy-policy",
            isInternal: true,
          },
          {
            id: "2",
            title: "Terms & Conditions",
            to: "/terms-and-conditions",
            isInternal: true,
          },
        ],
      },
      {
        id: "3",
        title: "Support",
        links: [
          {
            id: "1",
            title: "About Us",
            to: "/about",
            isInternal: true,
          },
          {
            id: "2",
            title: "Contact Us",
            to: "info@nobodysurf.com",
            isInternal: false,
          },
          {
            id: "3",
            title: "Submit Your Video",
            to: "https://docs.google.com/forms/d/e/1FAIpQLScaUIZ3Mr2ZILZ4k7E3MDdpY11IvCRvjzueN5sbb_SZAfK1AQ/viewform",
            isInternal: false,
          },
        ],
      },
      {
        id: "4",
        title: "App",
        links: [
          {
            id: "1",
            title: "iOS & Apple TV",
            to: externalUrls.appDownload,
            compactTo: externalUrls.appDownload,
            isInternal: false,
          },
          {
            id: "2",
            title: "Android",
            to: externalUrls.appDownload,
            compactTo: externalUrls.appDownload,
            isInternal: false,
          },
        ],
      },
    ],
  },
  modal: {
    showInterval: 172800, // 48時間
  },
}

export default config
